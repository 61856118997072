/* src/styles.css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
h1{
  font-size: 1.5rem!important
}

header {
  padding: 20px;
}
  
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  nav ul li {
    margin: 0 10px;
  }
  
  nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
  nav ul li a:hover {
    color: #ccc;
  }


.vertical-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.vertical-item {
  padding: 20px;
  border: 1px solid black;
}
/* Intro */

#Home {
  height: 80vh;
  margin-bottom: 20px; 
  text-align: center;
}
#Home .intro-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh !important; 
}
#ParticleContainer {
  position: relative
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}

#your-div {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Timeline */
#Experiences{
  text-align: center;
}
/* Timeline */
#Education{
  text-align: center;
}
/* Draft */
#Journey h1{
  text-align: center;
  margin-bottom: 2rem;
}

#VerticalTimeline{
  background-color: #8e8d8a;
}

.vertical-timeline-element-date{
  color:var(--bs-gray-900)!important;
}
.vertical-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  background-color: #8e8d8a!important;
  top: 0;
  left: 18px;
  height: 98%;
  width: 4px;
  background: var(--line-color);
}
.material-icons{
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 40%;
  margin-left: -12px;
  margin-top: -12px;
}
.vertical-timeline-element h3{
  font-size:  18px;
}
.vertical-timeline-element h5{
  font-size:  0.9rem;
}

.vertical-timeline-element ul{
  font-size:  0.875rem;
}
.vertical-timeline-element .chip-skill{
margin-right: 5px;
}
/* Project Section */
.ProjectSection {
  /* background-color: #EAE7DC; */
  /* height: 65vh; */
  margin-bottom: 20px; 
  padding-bottom: 20px; 
  padding-top: 20px; 
  text-align: center;
}
.ProjectSection .Project-content{
  top:40%;
  left: 40%;
}

.ProjectSection h1{
margin-bottom: 20px;
}
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.card-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.card{
  margin-right: 30px;
  flex-grow: 1;

  margin:  5px; /* Adjust the value to control the horizontal spacing */
  flex-basis: calc(33.33% - 10px); /* Adjust the value to control the card width */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .card {
    flex-basis: 100%; /* Set the card width to 100% on smaller screens */
  }
}

/* Skills Section*/
.SkillsSection {
  margin-bottom: 20px; 
  padding-bottom: 20px; 
  /* padding-top: 20px;  */
  text-align: center;
}

.SkillsSection .skills-content{
  top:40%;
  left: 40%;
}

.Underline {
  width: 200px;
  height: 4px;
  display: flex;
  background-color: #8e8d8a;
}


/* Footer */

footer {
	background: #1F1F1F;
	min-height: 100px;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: relative;
	text-align: center;
}

footer a,
footer a:visited {
	color: #fff;
  text-decoration:none;
}

footer a:hover,
footer a:focus {
	color: #fff;
}
.footer-icon{
font-size: 20px;
}

.social-links {
	margin-top: 50px;
	font-size: 22px;
}